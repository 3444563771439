import revive_payload_client_4sVQNw7RlN from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hwlJ5PmHMh from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_client_BKqc0fddj1 from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt-laravel-echo/dist/runtime/plugin.client.js";
import plugin_eskviOYyLt from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_vfCt6Q18Tc from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import axios_QMFgzss1s4 from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/plugins/axios.ts";
import toastify_ScRgZgP9op from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/plugins/toastify.ts";
import v_expand_3OG3dVOPOk from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/plugins/v-expand.ts";
import vuetify_7h9QAQEssH from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_hwlJ5PmHMh,
  plugin_Jozdw60ZsE,
  plugin_client_BKqc0fddj1,
  plugin_eskviOYyLt,
  plugin_vfCt6Q18Tc,
  axios_QMFgzss1s4,
  toastify_ScRgZgP9op,
  v_expand_3OG3dVOPOk,
  vuetify_7h9QAQEssH
]