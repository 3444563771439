import axios from "axios";

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const BASE_API_URL: string = runtimeConfig.public.apiBaseUrl as string;

  const api = axios.create({
    baseURL: BASE_API_URL,
    withCredentials: true,
    withXSRFToken: true,
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        navigateTo("/login");
      }

      return Promise.reject(error);
    }
  );

  nuxtApp.provide("axios", api);
});
