<template>
  <svg
    width="189"
    height="80"
    viewBox="0 0 189 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_179_17673)">
      <path
        d="M60.6687 63.8219H52.0482V78.8674H31.1476V63.8219H0V48.4528L32.5301 1.13232H52.0482V48.1293H60.6687V63.8219ZM31.1476 48.1293V39.0696C31.1476 37.3709 31.2289 35.106 31.3102 32.194C31.4729 29.282 31.5542 27.826 31.6355 27.6642H31.0663C29.8464 30.4144 28.4639 32.922 26.9187 35.3487L18.2982 48.1293H31.1476Z"
        fill="#0D0213"
      />
      <path
        d="M122.639 40.1213C122.639 53.8726 120.281 63.9838 115.726 70.3741C111.091 76.7644 104.015 80 94.4191 80C85.0667 80 78.0727 76.6835 73.2745 69.9697C68.5576 63.2558 66.1992 53.3064 66.1992 40.1213C66.1992 26.2892 68.4763 16.178 73.1932 9.70677C77.8287 3.23559 84.904 0 94.5004 0C103.772 0 110.847 3.39737 115.564 10.1112C120.281 16.8251 122.639 26.7745 122.639 40.1213ZM87.1811 40.1213C87.1811 48.9383 87.7504 55.0051 88.8076 58.2406C89.9462 61.5571 91.7354 63.1749 94.3378 63.1749C96.9402 63.1749 98.8107 61.4762 99.9492 58.0789C101.006 54.6815 101.576 48.6956 101.576 40.1213C101.576 31.4661 101.006 25.4803 99.9492 22.002C98.8107 18.5238 97.0215 16.8251 94.4191 16.8251C91.8167 16.8251 89.9462 18.5238 88.889 21.8402C87.7504 25.1567 87.1811 31.2235 87.1811 40.1213Z"
        fill="#0D0213"
      />
      <path
        d="M189 63.8219H180.379V78.8674H159.479V63.8219H128.331V48.4528L160.861 1.13232H180.379V48.1293H189V63.8219ZM159.56 48.1293V39.0696C159.56 37.3709 159.641 35.106 159.723 32.194C159.885 29.282 159.967 27.826 160.048 27.6642H159.479C158.259 30.4144 156.876 32.922 155.331 35.3487L146.629 48.2102H159.56V48.1293Z"
        fill="#0D0213"
      />
    </g>
    <defs>
      <clipPath id="clip0_179_17673">
        <rect width="189" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
