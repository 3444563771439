// plugins/v-expand.ts

import { type DirectiveBinding } from "vue";

enum Directions {
  WIDTH = "width",
  HEIGHT = "height",
}
const DEFAULT_DELAY = 300;

function setDefaultTransitionStyles(el: HTMLElement, delay: number): void {
  el.style.overflow = "hidden";
  el.style.transition = `max-height ${delay}ms ease-in-out, max-width ${delay}ms ease-in-out`;
}

function setDimensionStyle(
  el: HTMLElement,
  direction: Directions,
  value: number
): void {
  if (direction === Directions.WIDTH) {
    el.style.maxWidth = `${value}px`;
  } else {
    el.style.maxHeight = `${value}px`;
  }
}

function setClearStyleTimeout(
  el: HTMLElement,
  delay: number,
  callback?: () => void
): void {
  if (el.dataset.timeoutId) {
    clearTimeout(Number(el.dataset.timeoutId));
  }

  const timeout = setTimeout(() => {
    el.removeAttribute("style");
    if (callback) callback();
    if (el.dataset.timeoutId) {
      delete el.dataset.timeoutId;
    }
  }, delay);

  el.dataset.timeoutId = String(timeout);
}

const expand = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const shouldExpand = binding.modifiers.invert
      ? !binding.value
      : binding.value;

    if (!shouldExpand) el.style.display = "none";
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value === binding.oldValue) return;

    const _delay =
      binding.arg && !isNaN(Number(binding.arg))
        ? Number(binding.arg)
        : DEFAULT_DELAY;
    const _direction = binding.modifiers[Directions.WIDTH]
      ? Directions.WIDTH
      : Directions.HEIGHT;

    const shouldExpand = binding.modifiers.invert
      ? !binding.value
      : binding.value;

    if (shouldExpand) {
      setDimensionStyle(el, _direction, 0);
      setDefaultTransitionStyles(el, _delay);
      el.style.display = "";

      setDimensionStyle(
        el,
        _direction,
        _direction === Directions.WIDTH ? el.scrollWidth : el.scrollHeight
      );
      setClearStyleTimeout(el, _delay);
    } else {
      setDefaultTransitionStyles(el, _delay);
      setDimensionStyle(
        el,
        _direction,
        _direction === Directions.WIDTH ? el.scrollWidth : el.scrollHeight
      );

      setTimeout(() => {
        setDimensionStyle(el, _direction, 0);
      });

      setClearStyleTimeout(el, _delay, () => (el.style.display = "none"));
    }
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("expand", expand);
});
